<template>
  <div class="p-5 bg-white shadow-md rounded-lg">
    <h3 class="text-lg font-semibold">Tag aanmaken</h3>

    <hr class="my-5">

    <TagGenerator></TagGenerator>

  </div>
</template>

<script>
// import TwoFactoreAuthentication from '@/components/user/TwoFactoreAuthentication.vue'
// import ProfileUpdate from '@/components/user/ProfileUpdate.vue'
// import PasswordUpdate from '@/components/user/PasswordUpdate.vue'
import TagCreate from '@/components/tag/TagCreate.vue';
import TagGenerator from "@/components/tag/TagGenerator.vue";

export default {
  components: {
    TagGenerator,
    TagCreate
  }
}
</script>

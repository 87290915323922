<template>
  <div class="p-5 bg-white shadow-md rounded-lg">
    <h3 class="text-lg font-semibold">Dashboard</h3>

    <hr class="my-5">

    <h2 class="mb-2 text-lg font-semibold text-gray-900">
      {{ currentUser?.business_name }}
    </h2>

    <router-link :to="{ name: 'Tags' }" class="px-4 py-3 inline-flex mt-3 rounded bg-blue-300 hover:bg-blue-400">
      Bekijk tags
    </router-link>



  </div>
</template>

<script>
import {mapActions, mapStores} from "pinia";
import {useAuthStore} from "@/stores/auth.js";

export default {
  computed: {
    ...mapStores(useAuthStore),
    currentUser() {
      return this.authStore?.currentUser
    },
  }
}
</script>



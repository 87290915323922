<template>
  <div class="p-5 bg-white shadow-md rounded-lg">
    <h3 class="text-lg font-semibold">
      Jouw tag{{ tags.length === 1 ? '' : 's' }}
      <span class="text-gray-400">({{ tags.length }})</span>:
    </h3>
    <ul class="max-w-5xl divide-y divide-gray-200">
      <li class="pb-3 sm:pb-4 py-5" v-for="tag in tags" :key="tag.id">
        <div class="flex flex-col md:flex-row md:items-center p-6 rounded-xl bg-blue-100">
          <div class="mr-4">
            <img class="w-24 h-24" :src="`data:image/png;base64,${tag.qr_code}`" />
          </div>
          <div class="flex-0 min-w-0 mt-3 md:mt-0">
            <p class="text-1xl font-medium text-gray-900 truncate">
              {{ tag.name }}
            </p>
            <p class="text-sm text-gray-500 mt-2">
              <strong>{{ tag.url }}</strong> <br>
              ({{ tag.target_url }})
            </p>
            <a v-if="tag.download_url" :href="tag.download_url" target="_blank" class="inline-flex mt-3 text-sm text-gray-500 font-bold hover:underline hover:text-gray-800">
              > Download QR-codes
            </a>
          </div>
          <div class="flex ml-auto items-center text-base font-semibold text-gray-900">
            Triggers: {{ tag.events_count }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions} from "pinia";
import {useAuthStore} from "@/stores/auth.js";

export default {
  data() {
    return {
      tags: []
    }
  },
  mounted() {
    this.fetchTags();
  },
  methods: {
    ...mapActions(useAuthStore, ['attempt_user']),
    fetchTags() {
      axios.get('/api/tags')
        .then((response) => {
          this.tags = response.data.data
          console.log(response.data.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>


